export const RepostIcon = (props: { color?: string }) => {
  return (
    <svg
      fill={props.color}
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M19 7a1 1 0 0 0-1-1h-8v2h7v5h-3l3.969 5L22 13h-3V7zM5 17a1 1 0 0 0 1 1h8v-2H7v-5h3L6 6l-4 5h3v6z" />
    </svg>
  );
};
