export const FUNCTIONS_BASE = process.env.FUNCTIONS_BASE;

console.log('FUNCTIONS_BASE', FUNCTIONS_BASE);

export const ORCID_CLIENT_ID = process.env.ORCID_CLIENT_ID;
export const ORCID_API_URL = 'https://orcid.org';
export const APP_URL = process.env.APP_URL;
export const IFRAMELY_API_KEY = process.env.IFRAMELY_API_KEY;
export const IFRAMELY_API_URL = process.env.IFRAMELY_API_URL;

export const ORCID_REDIRECT_URL = APP_URL;

export const TWITTER_API_URL = 'https://api.twitter.com';

export const WALLETCONNECT_PROJECT_ID = 'c12fdc701fd336cf8dc059f6784906bc';
export const MAGIC_API_KEY = 'pk_live_A603B0287DAF3C97';

export const BUILD_ID = process.env.REACT_APP_VERSION;

export const SUPPORT_EMAIL_ADDRESS = 'support@sense-nets.xyz';
